import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Contact = ({ data, location }) => {
  const isFormSubmitted = location.search === '?sent';
  return (
    <Layout>
      <SEO title="Contacta con nosotros | asdloop" description="Si tienes un proyecto Drupal y crees que te podemos ayudar con alguna de sus fases, escribenos a info@asdloop.com o llámanos al 655 75 35 78, y cuéntanos tu proyecto." />
      <section className="jumbotron mb-0 pt-5" id="contact">
        <div className="container">
          {isFormSubmitted && (
            <div className="alert alert-success" role="alert">
            Gracias por tu mensaje. Nos pondremos en contacto contigo lo antes posible
            </div>
          )}
          <div className="row">
            <div className="col-lg-6 mb-5">
              <h2 className="display-4 font-weight-bold mb-3">Contacta con nosotros</h2>
              <h4>Si tienes un proyecto Drupal y necesitas ayuda con alguna de sus fases, ponte en contacto con nosotros y cuéntanos tu proyecto.</h4>
            </div>
            <div className="col-md-12 mx-auto">
              <div className="card">
                <div className="row">
                  <div className="col-lg-8">
                    <form id="contact-form" action="/contacta?sent" name="contact" method="POST" netlify-honeypot="bot-field" data-netlify="true">
                      <input type="hidden" name="form-name" value="contact" />
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Nombre</label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="fas fa-user" />
                                  </span>
                                </div>
                                <input type="text" name="nombre" className="form-control" placeholder="Nombre" required/>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Empresa</label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="fas fa-building" />
                                  </span>
                                </div>
                                <input type="text" name="empresa" className="form-control" placeholder="Empresa" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Email</label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="fas fa-envelope" />
                              </span>
                            </div>
                            <input type="email" name="email" className="form-control" placeholder="Correo electrónico" required />
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Mensaje</label>
                          <textarea name="mensaje" className="form-control" rows={6} defaultValue={""} placeholder="Mensaje" required />
                        </div>
                        <p className="d-none">
                          <label>No rellenes esto si eres humano <input
                            name="bot-field"/></label>
                        </p>
                        <div className="row">
                          <div className="col-md-6">
                            <button type="submit" className="btn btn-primary">Enviar</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-lg-4">
                    <div className="bg-primary h-100 p-5 text-white">
                      <h4 className="card-title">Datos de contacto</h4>
                      <p>Escribe o llámanos, intentamos contestar a todas las peticiones en 24 horas.</p>
                      <p className="font-weight-bold">Horario</p>
                      <p>Lun-Vie: 9:00 AM - 15:00 PM</p>
                      <div className="mt-5">
                        <i className="fas fa-map-marker-alt fa float-left w-40px" />
                        <p className="ml-4" style={{lineHeight: 1}}>Calle Cádiz 8, Pl. 3. Zaragoza</p>
                      </div>
                      <div>
                        <i className="fas fa-mobile-alt fa float-left w-40px" />
                        <p className="ml-4" style={{lineHeight: 1}}><a href="tel:+34655753578" className="text-white">655 75 35 78</a></p>
                      </div>
                      <div>
                        <i className="fas fa-envelope fa float-left w-40px" />
                        <p className="ml-4" style={{lineHeight: 1}}><a href="info@asdloop.com" className="text-white">info@asdloop.com</a></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Contact
